import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTradersAdmin } from "../store"; // Redux action to fetch traders
import { showNotification } from "./NotificationProvider";

const TraderManagementPanel = () => {
  const dispatch = useDispatch();
  const [traders, setTraders] = useState([]);

  // Fetch traders on component mount
  useEffect(() => {
    const fetchTraders = async () => {
      try {
        const response = await dispatch(getTradersAdmin()).unwrap();
        setTraders(response.data.data.traders); // Assuming the response contains the traders array
        console.log("Fetched traders:", response.data.data.traders);
      } catch (error) {
        console.error("Failed to fetch traders:", error);
      }
    };

    fetchTraders();
  }, [dispatch]);

  return (
    <div
      className="control-panel-trader-list"
      style={{
        backgroundColor: "#1C273A",
        minHeight: "100vh",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          width: "50%",
          margin: "1rem auto",
          backgroundColor: "#2E3B55", // Darker background for the table
          color: "#E0E6ED", // Light font color
        }}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              fontSize: "1.2rem", // Increase the font size
              color: "#E0E6ED", // Light font color for table cells
              borderBottom: "1px solid #3A4663", // Adjust the border color to match the theme
            },
            "& .MuiTableHead-root": {
              backgroundColor: "#3A4663", // Slightly darker background for the header
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Trader Name</TableCell>
              <TableCell align="center">Auth Token</TableCell>
              <TableCell align="center">Download Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {traders.map((trader) => (
              <TableRow key={trader._id}>
                <TableCell align="center">{trader.traderName}</TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(trader.traderAuthToken);
                      showNotification(
                        "Success",
                        "success",
                        "Auth token copied to clipboard"
                      );
                    }}
                    sx={{
                      color: "#E0E6ED",
                      textTransform: "none",
                      fontSize: "1rem",
                    }}
                  >
                    Copy Auth Token
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    href={`https://panel.easypropay.com${trader.downloadUrlDB}`}
                    target="_blank"
                    sx={{
                      backgroundColor: "#28a745",
                      color: "white !important",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#218838",
                      },
                    }}
                  >
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TraderManagementPanel;
