import { createSlice } from "@reduxjs/toolkit";

import {
  getWithdrawalQueue,
  getWithdrawalHistory,
  approveWithdrawal,
  declineWithdrawal,
} from "../thunks/Withdrawal";

const withdrawalSlice = createSlice({
  name: "withdrawals",
  initialState: {
    withdrawals: [],
    isLoading: false,
  },

  extraReducers(builder) {
    builder
      .addCase(getWithdrawalQueue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWithdrawalQueue.fulfilled, (state, action) => {
        state.withdrawals = action.payload;
        state.isLoading = false;
      });

    builder
      .addCase(getWithdrawalHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWithdrawalHistory.fulfilled, (state, action) => {
        state.withdrawals = action.payload;
        state.isLoading = false;
      });

    builder
      .addCase(approveWithdrawal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveWithdrawal.fulfilled, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(declineWithdrawal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(declineWithdrawal.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export const withdrawalReducer = withdrawalSlice.reducer;
