import { createSlice } from "@reduxjs/toolkit";

import {
  login,
  getMe,
  updateMe,
  updatePassword,
  updateAuthenticatorCode,

  //for isLoading
  createUser,
  updateUser,
  unblockUser,
  getUsers,
  blockUser,
  changeUserPassword,
  getTotalInfo,
  getLastMonthInfo,
} from "../thunks/User";

import { getDepositQueue, getDepositHistory } from "../thunks/Deposit";
import { getWithdrawalQueue, getWithdrawalHistory } from "../thunks/Withdrawal";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isLoading: false,
  },
  //   reducers: {
  //     setUser: (state, action) => {
  //       state.user = action.payload;
  //     },
  //   },
  extraReducers(builder) {
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.user = action.payload.data.data;
    });

    builder.addCase(updateMe.fulfilled, (state, action) => {
      state.user = action.payload.data.data;
    });

    builder.addCase(updatePassword.fulfilled, (state, action) => {
      console.log(action.payload.data);
      state.user = action.payload.data.data;
    });

    builder.addCase(updateAuthenticatorCode.fulfilled, (state, action) => {
      state.user.user.googleAuthenticatorCode =
        action.payload.data.googleAuthenticatorCode;
    });

    //for isLoading
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(blockUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(blockUser.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(changeUserPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeUserPassword.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getTotalInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getTotalInfo.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getLastMonthInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getLastMonthInfo.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const userReducer = userSlice.reducer;
