import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

const login = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post("/user/login", JSON.stringify(data), {
        headers,
      });

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const logout = createAsyncThunk(
  "user/logout",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.post(
        "/user/logout",
        {},
        {
          headers,
        }
      );

      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getMe = createAsyncThunk("user/getMe", async () => {
  const headers = { "Content-Type": "application/json" };

  const response = await axios
    .get("/user/me", {
      headers,
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      // Promise.reject(err.response.data.message);
    });

  return response;
});

const updateMe = createAsyncThunk("user/updateMe", async (data) => {
  const headers = { "Content-Type": "application/json" };

  const response = await axios
    .patch("/user/updateMe", JSON.stringify(data), {
      headers,
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      Promise.reject(err.response.data.message);
    });

  return response;
});

const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.patch(
        "/user/updatePassword",
        JSON.stringify(data),
        {
          headers,
        }
      );

      return response;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const updateAuthenticatorCode = createAsyncThunk(
  "user/updateAuthenticatorCode",
  async (_, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await axios.patch(
        "/user/updateAuthenticatorCode",
        {},
        {
          headers,
        }
      );

      return response;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const loginStep1 = createAsyncThunk(
  "user/loginStep1",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.post(
        "/user/loginStep1",
        JSON.stringify(data),
        {
          headers,
        }
      );

      return response.data;
    } catch (err) {
      console.log("user thunk", err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const loginStep2 = createAsyncThunk(
  "user/loginStep2",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.post(
        "/user/loginStep2",
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.post(
        "/admin/createUser",
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getUsers = createAsyncThunk(
  "user/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.get("/admin/getUsers", {
        headers,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.patch(
        "/admin/updateUser",
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const changeUserPassword = createAsyncThunk(
  "user/changeUserPassword",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.patch(
        "/admin/changeUserPassword",
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const blockUser = createAsyncThunk(
  "user/blockUser",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.patch(
        "/admin/blockUser",
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getTotalInfo = createAsyncThunk(
  "user/getTotalInfo",
  async (data, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.post(
        "/user/todayTotalInfo",
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

const getLastMonthInfo = createAsyncThunk(
  "user/getLastMonthInfo",
  async (_, { rejectWithValue }) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await axios.get("/user/lastMonthDeposits", {
        headers,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data.message);
    }
  }
);

export {
  login,
  getMe,
  updateMe,
  updatePassword,
  updateAuthenticatorCode,
  loginStep1,
  loginStep2,
  createUser, //admin
  updateUser, //admin
  getUsers, //admin
  changeUserPassword,
  blockUser, //admin
  logout,
  getTotalInfo,
  getLastMonthInfo,
};
