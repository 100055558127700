import { createSlice } from "@reduxjs/toolkit";

import { getTraders, getTradersForUserManagement } from "../thunks/Trader";

const traderSlice = createSlice({
  name: "trader",
  initialState: {
    traders: [],
    tradersForUserManagement: [],
  },

  extraReducers(builder) {
    builder.addCase(getTraders.fulfilled, (state, action) => {
      state.traders = action.payload.data.data.traders;
    });
    builder.addCase(getTradersForUserManagement.fulfilled, (state, action) => {
      state.tradersForUserManagement = action.payload.data.data.traders;
    });
  },
});

export const traderReducer = traderSlice.reducer;
