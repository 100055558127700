import { createSlice } from "@reduxjs/toolkit";

import {
  getDepositQueue,
  getDepositHistory,
  getUserDepositHistory,
} from "../thunks/Deposit";

const depositSlice = createSlice({
  name: "deposits",
  initialState: {
    deposits: [],
    loading: false,
    error: null,
    isLoading: false,
  },

  extraReducers(builder) {
    builder
      .addCase(getDepositQueue.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getDepositQueue.fulfilled, (state, action) => {
        state.deposits = action.payload;
        state.isLoading = false;
      })
      .addCase(getDepositQueue.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      });

    builder
      .addCase(getDepositHistory.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getDepositHistory.fulfilled, (state, action) => {
        state.deposits = action.payload;
        state.isLoading = false;
      })
      .addCase(getDepositHistory.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      });

    builder.addCase(getUserDepositHistory.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(getUserDepositHistory.fulfilled, (state, action) => {
      state.deposits = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserDepositHistory.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
});

export const depositReducer = depositSlice.reducer;
